import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import BlogItem from "../components/blogItem";
import BlogCategory from "../components/blog-category";
import Nav from '../components/nav';
import moment from 'moment';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isBlogLoaded: false,
            blogContent: [],
            isAwardLoaded: false,
            awardContent: [],
            isCategoryLoaded: false,
            categoryContent: [],
            load_more: 10,
            pageContent: [],
            ispageLoader: false,
            category_count: null,
            year_count: null,
            category_name:null
        }
    }

    async componentDidMount() {
        let category_name = this.props.params["*"];
        this.getAllBlog();
        this.getAllCategoryByType();
        this.getPageDetailsById();
        this.setState({category_name:category_name})
    }

    componentWillReceiveProps(nextProps) {
        let category_name = nextProps.params["*"];
        this.setState({category_name:category_name})
        this.getAllBlog();
        this.getAllCategoryByType();
        this.getPageDetailsById();
    }

    load_more = () => {
        this.setState({ load_more: this.state.load_more + 10 })
    }

    getAllBlog() {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/blog")
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data
                    // console.log("filteredDates", filteredDates)

                    // let counts = {};
                    // result.data.forEach(el => counts[el].category = 1  + (counts[el].category || 0))
                    // console.log("counts", counts)


                    let result2 = result.data.reduce(function (r, a) {
                        r[a.category] = r[a.category] || [];
                        r[a.category].push(a);
                        return r;
                    }, Object.create(null));
                    // console.log("result2", result2);


                    let result2publish_date = result.data.reduce(function (r, a) {
                        // let year = moment(a.publish_date,"YYYY-MM-DD").year()
                        // console.log("year", year + a.publish_date)
                        r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [];
                        // console.log(r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [])
                        r[moment(a.publish_date, "YYYY-MM-DD").year()].push(a);
                        // console.log(a)
                        return r;
                    }, Object.create(null));
                    // console.log("result2publish_date", result2publish_date);



                    this.setState({
                        isBlogLoaded: true,
                        blogContent: filteredDates,
                        category_count: result2,
                        year_count: result2publish_date
                    });
                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    getAllCategoryByType() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/blog")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: result
                    });
                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }

    getPageDetailsById() {
        fetch("https://tatva.com/backendapi/api/v1/cms/602b587f4a831648cdc13835")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ispageLoader: true,
                        pageContent: result,
                    });
                },
                (error) => {
                    this.setState({
                        ispageLoader: true,
                        error
                    });
                }
            )
    }

    render() {
        const { isBlogLoaded, blogContent, isCategoryLoaded, categoryContent, load_more, pageContent, ispageLoader,
            category_count,
            year_count,
            category_name
        } = this.state;
        return (
            <Layout>

                {ispageLoader ?
                    <Nav
                        active="blog"
                        pageTitle={pageContent.data?.title}
                        title={pageContent.data?.document_title}
                        description={pageContent.data?.meta_description}
                        keywords={pageContent.data?.meta_tag}
                    ></Nav>
                    : ''
                }

                {ispageLoader ?
                    <Intro
                        search="true"
                        searchType="blog"
                        title="Our Blog"
                        description="Our blog provides legal and commercial information, analysis and updates of national and regional relevance.">

                    </Intro>
                    : ''}

                <div className="main-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <div className="blog-container">
                                    {isBlogLoaded && isCategoryLoaded ?
                                        <React.Fragment>
                                            {/* {JSON.stringify(year_count[category_name])} */}
                                             {year_count[category_name].slice(0, load_more).map(item => (
                                                <div className="" key={item._id}>
                                                    <BlogItem
                                                        category_show="true"
                                                        category={item.category}
                                                        title={item.title}
                                                        author={item.created_by}
                                                        date={item.publish_date}
                                                        short_content={item.short_description}
                                                        slug={item.slug}
                                                        description={item.description}
                                                    >
                                                    </BlogItem>
                                                </div>
                                            ))}
                                            <button className="read_more" onClick={this.load_more}>Load More</button>
                                        </React.Fragment>
                                        : ''}
                                </div>
                            </div>
                            {isBlogLoaded && isCategoryLoaded ?
                                <BlogCategory
                                    category_count={category_count}
                                    year_count={year_count}
                                    className={"col-md-3 col-sm-12"}
                                    categoryContent={categoryContent}
                                ></BlogCategory>
                                : ''}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;